<script>
import Layout from '@/router/layouts/main';

export default {
  locales: {
    pt: {
      'Downloads': 'Downloads',
    },
    es: {
      'Downloads': 'Descargas',
    }
  },
  components: {
    Layout,
  },
  data() {
    return {

    };
  },
  methods: {
  },
  mounted() {
  }
};
</script>

<template>
  <Layout>
    <div class="row">
      <div class="col-12">
        <div class="page-title-box d-flex justify-content-between">
          <h4 class="mb-0 font-size-18">{{ t('Downloads') }}</h4>
        </div>
      </div>
    </div>

    <div class="row">
      <div class="col-lg-3">
      </div>
    </div>
  </Layout>
</template>